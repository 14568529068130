<template>
  <ul class="legal">
    <li v-for="(item, index) in legalItems" :key="index">
      <link-component :href="item.url" :label="item.label"/>
    </li>
  </ul>
</template>

<script>
  import footerItems from '../footer-items.json'
  import LinkComponent from './Link'

  export default {
    name: "Legal",
    components: { LinkComponent },
    data () {
      const legalItems = []
      footerItems.forEach(item => {
        if (item.type === 'legal') {
          legalItems.push(item);
        }
      });
      return { legalItems };
    }
  }
</script>

<style lang='scss' scoped>
  @import '../assets/variables';

  .legal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font: inherit;
    text-transform: uppercase;
    line-height: 2;
    list-style: none;

    a {
      color: white;
    }


    @media (min-width: $break-sm) {
      flex-direction: row;
    }

    @media (min-width: $break-lg) {
      width: 80%;
    }
  }
</style>