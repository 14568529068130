<template>
  <div class='grid-item'>
    <headline :text="item.label"/>
    <ul v-if="!item.isCallToAction">
      <li v-for="(child, index) in item.children" :key="index">
        <link-component :url="child.url" :label="child.label" />
      </li>
    </ul>
    <div v-else>
      <div class="call-to-action">
        <div class="call-to-hotline">
          Hotline<br>
          <i class="icon-footer-hotline-underline"></i>
        </div>
        <div class="call-to-number">
          <a href="tel:+4971189462111" class="s-footer__menu--number">0800-1004044 (kostenfrei)</a>
          <div class="call-to-schedule">Mo. - Fr.: 08:00 - 18:00 Uhr</div>
        </div>
      </div>
      <button-component v-for="(child, index) in item.children" :key="index" :label="child.label" :url="child.url" />
    </div>

  </div>
</template>

<script>
  import ButtonComponent from './Button'
  import Headline from './Headline'
  import LinkComponent from './Link'

  export default {
    name: "Grid",
    props: {
      item: Object, 
      key: Number
    },
    components: { ButtonComponent, Headline, LinkComponent}
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';
  
  .grid-item {
    margin-bottom: 3.125rem;
    font: inherit;
    
    ul {
      list-style: none;

      li {
        line-height: 2;
      }
    }

    .call-to-action {
      display: flex;
      flex-direction: column;
      margin-bottom: 3.125rem;

      .call-to-hotline {
        font-size: 2.625rem;
        font-family: "Handelson", Arial, Helvetica, sans;
        margin-right: 1.875rem;

        @media (min-width: $break-lg) {
          font-size: 3rem;
        }
      }

      .icon-footer-hotline-underline {
        display: block;
        font-size: .5rem;
        color: $color-primary;
      }

      .call-to-number {
        margin-top: .9375rem;

        a {
          color: white;
          font-size: 1.375rem;
          text-decoration: none;
        }
        .call-to-schedule {
          font-size: .75rem;
          line-height: 2.4em;
        }
        @media (min-width: $break-sm) {
          margin-top: 0;
        }
      }

      @media (min-width: $break-sm) {
        flex-direction: row;
      }
    }
  }
</style>