<template>
  <div class='grid'>
    <grid-item v-for="(item, index) in gridItems" :key="index" :item="item" />
  </div>
</template>

<script>
  import GridItem from './GridItem';
  import footerItems from '../footer-items.json';

  export default {
    name: 'Grid',
    components: { GridItem },
    data () {
      const gridItems = []
      footerItems.forEach(item => {
        if (item.type === 'grid') {
          gridItems.push(item);
        }
      });
      return { gridItems };
    }
  }
</script>

<style lang='scss' scoped>
  @import '../assets/variables';

  .grid {
    width: 100%;
    display: grid;
    justify-content: space-between; 
    column-gap: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);

    @media (min-width: $break-sm) {
      grid-template-columns: 2fr 3fr;
      grid-template-rows: repeat(2, auto);
    }

    @media (min-width: $break-lg) {
      grid-template-columns: repeat(3, 2fr) 5fr;
      grid-template-rows: auto;
    }
  }
</style>