<template>
  <h3>{{ text }}</h3>
</template>

<script>
export default {
  name: 'Headline',
  props: { text: String }
}
</script>

<style lang='scss' scoped>
  @import '../assets/variables.scss';

  h3 {
    margin-bottom: 1.125rem;
    color: $color-primary;
    text-transform: uppercase;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 400;
  }
</style>