<template>
  <a class="link" :href="url">{{ label }}</a>
</template>

<script>
export default {
  name: 'LinkComponent',
  props: { url: String, label: String }
}
</script>

<style lang='scss' scoped>
  .link {
    color: white;
    cursor: pointer;
    text-decoration: none;

    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }
</style>