<template>
  <nav>
    <div class="container">
      <grid/>
      <div class="payment-info">
        <headline text="Sicheres Bezahlen"/>
        <div>Kauf auf <b>Rechnung</b></div>
      </div>
      <legal/>
    </div>
  </nav>
</template>

<script>
  import Grid from "./Grid";
  import Headline from "./Headline";
  import Legal from "./Legal";

  export default {
    name: "Container",
    components: { Grid, Headline, Legal }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/variables.scss';

  nav {
    background-image: linear-gradient(to bottom, $color-secondary, $color-accent);
    color: white;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: .875rem;
    padding: 5.4375rem 0;


    .container {
      padding: 0 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 65%;

      .payment-info {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        text-transform: uppercase;
        font: inherit;
        line-height: 2;
        margin-bottom: 1.25rem;

        h3 {
          margin-right: 1.875rem;
        }
      }

      @media (min-width: $break-sm) {
        width: 85%;

        .payment-info {
          flex-direction: row;
        }
      }

      @media (min-width: $break-lg) {
        width: 90%;
        width: 1200px;
      }
    }
  }

</style>