<template>
  <container/>
</template>

<script>
  import Container from "./components/Container";

  export default {
    name: 'App',
    components: {
      Container
    }
  }
</script>

<style>
</style>
