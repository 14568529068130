<template>
  <a class="button" :href="url">{{ label }}</a>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: { url: String, label: String }
}
</script>

<style lang='scss' scoped>
  @import '../assets/variables.scss';

  .button {
    max-width: 11.25rem;
    min-width: 7.5rem;
    margin-right: 1.5625rem;
    font-size: .875rem;
    padding: .9375rem 2.1875rem;
    border: 1px solid #fff;
    border-radius: 100px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 1;
    letter-spacing: 1px;
    text-decoration: none;
    margin-bottom: .9375rem;

    &:hover, &:active, &:focus {
      color: $color-primary;
      border-color: $color-primary;
    }

    @media (min-width: $break-sm) {
      min-width: 0;
    }
  }
</style>